import React from "react";
import ReactDOM from "react-dom/client";

import "src/index.scss";

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { AuthProvider } from "src/context/AuthProvider";
import AboutPage from "src/pages/AboutPage/AboutPage";
import ContactsPage from "src/pages/ContactsPage/ContactsPage";
import HomePage from "src/pages/HomePage/HomePage";
import ProfilePage from "src/pages/ProfilePage/ProfilePage";
import WalletPage from "src/pages/WalletPage/WalletPage";
import "src/styles/global.scss";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/profile",
    element: <ProfilePage />,
  },
  {
    path: "/wallet",
    element: <WalletPage />,
  },
  {
    path: "/about",
    element: <AboutPage />,
  },
  {
    path: "/contacts",
    element: <ContactsPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthProvider>
      <RouterProvider router={router} />
    </AuthProvider>
  </React.StrictMode>
);
