import { Container } from "src/components/Container/Container";
import Typography from "src/components/Typography/Typography";

import styles from "src/pages/ProfilePage/ProfilePage.module.scss";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ProfileApi } from "src/api/domains/profile.api";
import { useScreenSize } from "src/hooks/useScreenSize";

const ProfilePage = () => {
  const [userData, setUserData] = useState();

  const navigate = useNavigate();
  const width = useScreenSize();

  const fetchUserInfo = async () => {
    try {
      const userInfo = await ProfileApi.getUserInfo();
      const publicInfo = await ProfileApi.getUserPublicInfo();
      setUserData({ ...userInfo, num_of_objects: publicInfo.num_of_objects });
    } catch (error) {
      navigate("/", { replace: true, state: { shouldOpenModal: true } });
    }
  };

  const firstLetter = userData?.first_name.slice(0, 1).toUpperCase();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return (
    <Container
      title={{ name: "Личный кабинет", href: "/" }}
      isFooterAbsolute={true}
    >
      <div className={styles.accountInfoContainer}>
        {userData?.avatar_url ? (
          <img
            src={userData.avatar_url}
            alt="profile"
            className={styles.accountImage}
          />
        ) : (
          <div className={styles.accountImage}>{firstLetter}</div>
        )}
        <div className={styles.accountInfo}>
          <div>
            <Typography size={20} weight={700}>
              {userData?.first_name}
            </Typography>
            <Typography size={15} weight={500} className={styles.userName}>
              @{userData?.username}
            </Typography>
          </div>
          {width > 786 && (
            <div className={styles.info}>
              <Typography as="div" align="center">
                <Typography size={20} weight={600}>
                  {userData?.subscribers_count}
                </Typography>
                <Typography size={20} weight={400} color="grey">
                  Подписчики
                </Typography>
              </Typography>
              <Typography as="div" align="center">
                <Typography size={20} weight={600}>
                  {userData?.subscriptions_count}
                </Typography>
                <Typography size={20} weight={400} color="grey">
                  Подписки
                </Typography>
              </Typography>

              <Typography as="div" align="center">
                <Typography size={20} weight={600}>
                  {userData?.num_of_objects ? userData?.num_of_objects : 0}
                </Typography>
                <Typography size={20} weight={400} color="grey">
                  Челенджей
                </Typography>
              </Typography>
            </div>
          )}
        </div>
      </div>

      {width < 786 && (
        <div className={styles.info}>
          <Typography as="div" align="center">
            <Typography size={20} weight={600}>
              {userData?.subscribers_count}
            </Typography>
            <Typography size={20} weight={400} color="grey">
              Подписчики
            </Typography>
          </Typography>
          <Typography as="div" align="center">
            <Typography size={20} weight={600}>
              {userData?.subscriptions_count}
            </Typography>
            <Typography size={20} weight={400} color="grey">
              Подписки
            </Typography>
          </Typography>

          <Typography as="div" align="center">
            <Typography size={20} weight={600}>
              {userData?.num_of_objects ? userData?.num_of_objects : 0}
            </Typography>
            <Typography size={20} weight={400} color="grey">
              Челенджей
            </Typography>
          </Typography>
        </div>
      )}

      {userData?.bio && (
        <Typography className={styles.aboutSelf}>{userData?.bio}</Typography>
      )}
    </Container>
  );
};

export default ProfilePage;
