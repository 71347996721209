/* eslint-disable react-hooks/exhaustive-deps */
import { HomePageCards } from "src/pages/HomePage/HomePageCards";

import styles from "src/pages/HomePage/HomePage.module.scss";

import clsx from "clsx";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AccountImage from "src/assets/images/account.png";
import WelletImage from "src/assets/images/wallet.png";
import { Container } from "src/components/Container/Container";
import { useAuth } from "src/context/AuthProvider";

const HomePage = () => {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState();

  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.shouldOpenModal) {
      setIsAuthModalOpen(true);
      navigate("/", { state: { shouldOpenModal: false } });
    }
  }, []);

  const handleOpenLink = (event, link) => {
    event.stopPropagation();
    if (isAuthenticated && link) {
      navigate(link);
    } else {
      setIsAuthModalOpen(true);
    }
  };

  return (
    <Container onOpenModal={setIsAuthModalOpen} isModalOpan={isAuthModalOpen}>
      <div className={clsx(styles.imagesContainer)}>
        {/* <HomePageCards
          src={EnterLivaImage}
          title="Выйди в Live"
          // onClick={(event) => handleOpenLink(event)}
        /> */}
        <HomePageCards
          src={AccountImage}
          title="Личный кабинет"
          onClick={(event) => handleOpenLink(event, "/profile")}
        />
        <HomePageCards
          src={WelletImage}
          title="Кошелек"
          onClick={(event) => handleOpenLink(event, "/wallet")}
        />
      </div>
    </Container>
  );
};

export default HomePage;
