// ----------------------------------------------------------------------

const extractResponse = (response) => {
  if (!!response.config.shouldReturnOriginalResponse) {
    return response;
  }

  return Object.assign(response, response.data);
};

const extractErrorResponse = (error) => {
  if (error.response) {
    error.response = extractResponse(error.response);
  }

  return Promise.reject(error);
};

// ----------------------------------------------------------------------

export const applyExtractorResponseInterceptor = (axiosInstance) => {
  axiosInstance.defaults.shouldReturnOriginalResponse = false;
  axiosInstance.interceptors.response.use(
    extractResponse,
    extractErrorResponse
  );

  return axiosInstance;
};

export const headersRequestInterceptor = (config) => {
  if (!config.headers) config.headers = {};
  config.headers["Accept"] = "application/json";
  config.headers["Authorization"] = window.localStorage.getItem("rupor:token")
    ? "Token " + window.localStorage.getItem("rupor:token")
    : "";
  config.headers["Content-Type"] = "application/json";
  return config;
};

export const applyHeadersRequestInterceptor = (axios) => {
  axios.interceptors.request.use(headersRequestInterceptor);
  return axios;
};
