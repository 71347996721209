import clsx from "clsx";
import { Footer } from "src/components/Footer";
import Header from "src/components/Header/Header";

import styles from "src/components/Container/Container.module.scss";
import Typography from "src/components/Typography/Typography";

import { ReactComponent as BackSvg } from "src/assets/images/icons/back.svg";
import { useScreenSize } from "src/hooks/useScreenSize";

export const Container = (props) => {
  const {
    children,
    className,
    titleClassName,
    title,
    onOpenModal,
    isModalOpan,
    header = true,
    footer = true,
    isFooterAbsolute = false,
  } = props;
  const width = useScreenSize();

  return (
    <div className={clsx("container", className)}>
      <>
        {header && (
          <Header onOpenModal={onOpenModal} isModalOpan={isModalOpan} />
        )}
        {title?.name && width > 786 && (
          <div className={clsx(styles.titleContainer, titleClassName)}>
            <a className={styles.backIcon} href={title.href}>
              <BackSvg />
            </a>
            <Typography size={36} weight={700} align="center">
              {title.name}
            </Typography>
          </div>
        )}
        {children}
      </>

      {footer && <Footer isAbsolute={isFooterAbsolute} />}
    </div>
  );
};
