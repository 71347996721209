import { useState } from "react";

// Number of input fields that make up SSN
const numOfFields = 5;

export const useMessageFields = () => {
  const [messageCode, setMessageCode] = useState({
    message1: "",
    message2: "",
    message3: "",
    message4: "",
  });

  return {
    handleChange: (e) => {
      const { maxLength, value, name } = e.target;
      const [fieldName, fieldIndex] = name.split("-");

      // Check if they hit the max character length
      if (value.length >= maxLength) {
        // Check if it's not the last input field
        if (parseInt(fieldIndex, 9) <= numOfFields) {
          // Get the next input field
          const nextSibling = document.querySelector(
            `input[name=${fieldName}-${parseInt(fieldIndex, 9) + 1}]`
          );

          // If found, focus the next field
          if (nextSibling !== null) {
            nextSibling.focus();
          }
        }
      } else {
        const previusSibling = document.querySelector(
          `input[name=message-${parseInt(fieldIndex - 1, 9)}]`
        );

        const currentSibling = document.querySelector(
          `input[name=message-${parseInt(fieldIndex, 9)}]`
        );

        if (currentSibling?.value === "" && previusSibling !== null) {
          previusSibling.focus();
        }
      }

      setMessageCode((prev) => {
        return {
          ...prev,
          [`message${fieldIndex}`]: value,
        };
      }); 
    },
    messageCode: Object.values(messageCode),
    setMessageCode: setMessageCode,
  };
};
