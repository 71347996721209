import { Container } from "src/components/Container/Container";

import styles from "src/pages/ContactsPage/ContactsPage.module.scss";

const ContactsPage = () => {
  return (
    <Container title={{ name: "Контакты", href: "/" }}>
      <ul className={styles.contacts}>
        <li>ООО "РУПОР"</li>
        <li>ИНН 9710076138</li>
        <li>КПП 771001001</li>
        <li>ОГРН 1197746355531</li>
        <li>Адрес: 123056 г. Москва, вн. тер. г. Муниципальный округ </li>
        <li>Пресненский, ул. 2-я Брестская.ю д. 5, помещ. 1/1</li>
        <li>rupor.company@mail.ru</li>
        <li>Техническая поддержка: support@rupor.me</li>
      </ul>
    </Container>
  );
};

export default ContactsPage;
