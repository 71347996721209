import clsx from "clsx";
import { NumericFormat, PatternFormat } from "react-number-format";
import styles from "src/components/Input/Input.module.scss";
import Typography from "src/components/Typography/Typography";

export const Input = (props) => {
  const { id, label, placeholder, className, error, format, ...rest } = props;

  const Format = format ? PatternFormat : NumericFormat;

  return (
    <div className={styles.container}>
      {label && (
        <label htmlFor={id}>
          <Typography color="grey">{label}</Typography>
        </label>
      )}
      <Format
        id={id}
        format={format}
        name={id}
        placeholder={placeholder}
        className={clsx(styles.input, className)}
        {...rest}
      />

      {error && (
        <Typography className={styles.error} weight={500}>
          {error}
        </Typography>
      )}
    </div>
  );
};
