import clsx from "clsx";
import Typography from "src/components/Typography/Typography";
import styles from "src/pages/HomePage/HomePage.module.scss";

export const HomePageCards = (props) => {
  const { src, title, href, onClick } = props;

  return (
    <a className={clsx(styles.imageWrapper)} onClick={onClick} href={href}>
      <Typography size={20} weight={600} className={styles.title}>
        {title}
      </Typography>
      <img src={src} alt="" />
    </a>
  );
};
