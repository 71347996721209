import clsx from "clsx";
import { useRef, useState } from "react";
import styles from "src/components/Header/Header.module.scss";

import logoSvg from "src/assets/images/logo.svg";
import { Link } from "src/components/Link/Link";
import { FaqModal } from "src/components/Modal/FaqModal";

// import { ReactComponent as CloseButton } from "src/assets/icons/close -button.svg";

export const BuregerMenu = (props) => {
  const { isOpen, onClose } = props;

  const ref = useRef(null);
  const [faqModal, setFaqModal] = useState(false);

  return (
    <>
      {isOpen && (
        <div
          className={clsx(styles.burgerMenuWrapper, isOpen && styles.open)}
          ref={ref}
        >
          <img src={logoSvg} alt="logo" />
          <nav className={styles.navigation}>
            {/* <Link href="">Выйти в Live</Link> */}
            <Link href="/profile">Личный кабинет</Link>
            <Link href="/wallet">Кошелек</Link>
            <Link href="/about">О нас</Link>
            <Link href="/contacts">Контакты</Link>
            <Link
              target="_blank"
              href={
                "https://storage.yandexcloud.net/talkme-prod/media/main/privace.html"
              }
              download
            >
              Правила использования
            </Link>
            <Link
              onClick={() => {
                setFaqModal(true);
                onClose();
              }}
            >
              FAQ
            </Link>
            <Link
              href={"http://t.me/talkme_live_bot?start=info"}
              target="_blank"
            >
              Написать нам
            </Link>
          </nav>
          <div className={styles.actionContainer}></div>
        </div>
      )}

      <FaqModal isOpen={faqModal} onClose={() => setFaqModal(false)} />
    </>
  );
};
