import Typography from "src/components/Typography/Typography";

import styles from "src/components/Header/Header.module.scss";

import { useEffect, useState } from "react";
import { RoundedButton } from "src/components/Buttons";
import { Input } from "src/components/Input/Input";
import { Modal } from "src/components/Modal/Modal";

import { ReactComponent as PhoneSvg } from "src/assets/images/icons/phone.svg";
import { CircledInput } from "src/components/Input/CircledInput";
import { useAuth } from "src/context/AuthProvider";
import { useMessageFields } from "src/hooks/useMessageFields";

import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { AuthApi } from "src/api/domains/auth.api";

import logoSvg from "src/assets/images/logo.svg";
import { BuregerMenu } from "src/components/Header/BurgerMenu";
import { useScreenSize } from "src/hooks/useScreenSize";

const Header = (props) => {
  const { onOpenModal, isModalOpan } = props;
  const navigate = useNavigate();

  const [openAuthFields, setOpenAuthFields] = useState();
  const [number, setNumber] = useState("");
  const [isMessageSend, setIsMessageSend] = useState(false);
  const [openBurgerMenu, setOpenBurgerMenu] = useState(false);
  const [name, setName] = useState(0);
  const [userName, setuserName] = useState(0);

  const width = useScreenSize();

  const [error, setError] = useState({
    numberError: "",
    nameError: "",
    userNameError: "",
  });

  const { handleChange, messageCode } = useMessageFields();

  const { isAuthenticated, setIsAuthenticated, setCoins, coins } = useAuth();

  const messageCodeValue = Object.values(messageCode).join("");

  const fatchCoins = async () => {
    try {
      const { coin } = await AuthApi.getUserCoins();

      setCoins(coin);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("rupor:token")) {
      fatchCoins();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseModal = (register) => {
    setError({});
    onOpenModal(false);
    setIsMessageSend(false);
    setOpenAuthFields(false);
  };

  const handleSendMessage = async () => {
    try {
      await AuthApi.sendConfirmCode(number);
      setIsMessageSend(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleConfirmMessage = async () => {
    try {
      const data = await AuthApi.confirmCode(number, messageCodeValue);
      if (data.status === 200) {
        localStorage.setItem("rupor:token", `${data.auth_token.access}`);
        setIsAuthenticated(true);
        localStorage.setItem("rupor:isAuth", true);
      }
      if (!data.first_name) {
        setIsMessageSend(false);
        setOpenAuthFields(true);
      } else {
        handleCloseModal(true);
      }
      fatchCoins();
    } catch (error) {
      console.error(error);
    }
  };

  const handleSaveUserName = async () => {
    try {
      await AuthApi.saveUserName(name, userName);
      handleCloseModal(true);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSignOut = async () => {
    const logout = await AuthApi.userLogout();

    if (logout.status === 200) {
      localStorage.removeItem("rupor:isAuth", true);
      localStorage.removeItem("rupor:token");
      setIsAuthenticated(false);
      navigate("/");
    }
  };

  return (
    <div className={styles.container}>
      {width > 768 && (
        <>
          <a href="/">
            <img src={logoSvg} alt="logo" />
          </a>
          <div className={styles.balance}>
            {/* {isAuthenticated && coins !== "" && (
              <>
                <Typography color="grey">Мой баланс</Typography>
                <Typography size={18} weight={700} className={styles.amount}>
                  {coins}
                  <CoinSvg />
                </Typography>
              </>
            )} */}
            {isAuthenticated ? (
              <RoundedButton className={styles.button} onClick={handleSignOut}>
                Выход
              </RoundedButton>
            ) : (
              <RoundedButton
                className={styles.button}
                onClick={() => onOpenModal(true)}
              >
                Вход
              </RoundedButton>
            )}
          </div>
        </>
      )}
      {width <= 768 && (
        <div className={styles.burgerMenuContainer}>
          <div
            className={clsx(styles.burgerIcon, openBurgerMenu && styles.open)}
            onClick={() => setOpenBurgerMenu((prev) => !prev)}
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </div>
          <img src={logoSvg} alt="logo" />
          <div>
            {isAuthenticated ? (
              <RoundedButton className={styles.button} onClick={handleSignOut}>
                Выход
              </RoundedButton>
            ) : (
              <RoundedButton
                className={styles.button}
                onClick={() => onOpenModal(true)}
              >
                Вход
              </RoundedButton>
            )}
          </div>
        </div>
      )}
      <BuregerMenu
        isOpen={openBurgerMenu && width <= 768}
        onClose={() => setOpenBurgerMenu(false)}
        coins={coins}
      />

      <Modal isOpen={isModalOpan} onClose={() => handleCloseModal(false)}>
        {!isMessageSend && !openAuthFields && (
          <>
            <Typography weight={700} size={20} className={styles.modalTitle}>
              Вход
            </Typography>
            <Input
              format="+### ## ### ## ##"
              type="tel"
              label="Введите номер телефона"
              onChange={(event) => setNumber(event.target.value)}
              value={number}
              placeholder="+998901234567"
              error={number.length >= 8 ? "" : error.numberError}
              onBlur={(event) => {
                if (event.target.value.length <= 8) {
                  setError({
                    ...error,
                    numberError:
                      "Поля не может быть пустым минимальная значения 8",
                  });
                }
              }}
            />
            <RoundedButton
              type="button"
              long
              className={styles.modalButton}
              disabled={!(number.length >= 8)}
              onClick={handleSendMessage}
            >
              Продолжить
            </RoundedButton>
          </>
        )}

        {isMessageSend && !openAuthFields && (
          <>
            <div className={styles.callPhoneContainer}>
              <PhoneSvg />
              <div style={{ marginLeft: "10px" }}>
                <Typography size={17} weight={600} className={styles.callPhone}>
                  +7 (XXX) XXX -{" "}
                  <Typography as="span" size={17} weight={600}>
                    XX - XX
                  </Typography>
                </Typography>
                <Typography size={17} weight={400}>
                  Входящий{" "}
                  <Typography as="span" size={17} weight={600}>
                    звонок или смс
                  </Typography>
                </Typography>
              </div>
            </div>
            <Typography className={styles.hint} align="center">
              Введите последние 4 цифры номера или код из смс
            </Typography>
            <div className={styles.circledInputContainer}>
              <CircledInput
                type="tel"
                maxLength="1"
                name="message-1"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-2"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-3"
                onChange={handleChange}
              />
              <CircledInput
                maxLength="1"
                type="tel"
                name="message-4"
                onChange={handleChange}
              />
            </div>

            <RoundedButton
              type="button"
              long
              className={styles.modalButton}
              disabled={!(messageCodeValue.length >= 4)}
              onClick={handleConfirmMessage}
            >
              Продолжить
            </RoundedButton>
          </>
        )}

        {openAuthFields && !isMessageSend && (
          <>
            <Typography weight={700} size={20} className={styles.modalTitle}>
              Регистрация
            </Typography>
            <Input
              label="Введите имя"
              placeholder="Имя *"
              error={name.length >= 4 ? "" : error.nameError}
              onChange={(event) => setName(event.target.value)}
              onBlur={(event) => {
                if (event.target.value.length < 4) {
                  setError({
                    ...error,
                    nameError:
                      "Поля не может быть пустым минимальная значения 4",
                  });
                }
              }}
            />
            <Input
              label="Введите никнейм"
              placeholder="Никнейм *"
              error={userName.length >= 4 ? "" : error.userNameError}
              onChange={(event) => setuserName(event.target.value)}
              onBlur={(event) => {
                if (event.target.value.length < 4) {
                  setError({
                    ...error,
                    userNameError:
                      "Поля не может быть пустым минимальная значения 4",
                  });
                }
              }}
            />
            <RoundedButton
              type="button"
              long
              className={styles.modalButton}
              disabled={!(name.length >= 4 && userName.length >= 4)}
              onClick={handleSaveUserName}
            >
              Продолжить
            </RoundedButton>
          </>
        )}
      </Modal>
    </div>
  );
};

export default Header;
