import styles from "src/components/Buttons/Button.module.scss";
import Typography from "src/components/Typography/Typography";

import clsx from "clsx";
import { ReactComponent as CoinSvg } from "src/assets/images/icons/coin.svg";

export const CoinsButton = (props) => {
  const { amount, price, coinImage, id, isSelected, onClick } = props;

  return (
    <button
      className={clsx(styles.coinButton, isSelected && styles.selected)}
      id={id}
      onClick={onClick}
    >
      <img src={coinImage} alt="" />
      <div className={styles.amount}>
        <Typography size={13}>Кол-во</Typography>
        <Typography size={30} weight={700}>
          {amount}
          <span>
            <CoinSvg
              style={{
                width: "28px",
                height: "28px",
                verticalAlign: "middle",
                marginLeft: "5px",
              }}
            />
          </span>
        </Typography>
      </div>
      <button className={styles.buyBtn}>
        <Typography weight={600}>{price}₽</Typography>
      </button>
    </button>
  );
};
