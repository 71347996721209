import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { CoinsButton } from "src/components/Buttons/CoinsButton";
import { Container } from "src/components/Container/Container";
import Typography from "src/components/Typography/Typography";
// import { Tabs } from "src/components/Tabs/Tabs";

import styles from "src/pages/WalletPage/WalletPage.module.scss";

import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { TarrifApi } from "src/api/domains/tariffs.api";
import { ReactComponent as PSvg } from "src/assets/images/icons/P.svg";
import { ReactComponent as CoinSvg } from "src/assets/images/icons/coin.svg";
import { ReactComponent as OrangeSvg } from "src/assets/images/icons/orange.svg";
import { useAuth } from "src/context/AuthProvider";

const WalletPage = () => {
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [isAgree, setIsAgree] = useState(false);
  const [amount, setAmount] = useState(false);

  const [tariffs, setTarrifs] = useState();
  const navigate = useNavigate();

  const { isAuthenticated, coins } = useAuth();

  const getTarriffs = async () => {
    const tariffs = await TarrifApi.getTarrifs();

    setTarrifs(tariffs.data);
  };

  const handleWithdrew = async () => {
    try {
      const response = TarrifApi.withdraw(amount);
      console.log(response);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/", { replace: true, state: { shouldOpenModal: true } });
    }

    getTarriffs();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleReplenishment = async () => {
    const { data } = await TarrifApi.replenishment(selectedCoin);

    const href = `https://paymentpage.ecommpay.com/payment?signature${data.signature}&payment_id=${data.payment_id}&project_id=${data.project_id}&payment_currency=${data.payment_currency}&payment_amount=${data.payment_amount}&interface_type=${data.interface_type}&customer_id=${data.customer_id}&cash_voucher_data=${data.cash_voucher_data}&customer_email=${data.customer_email}`;

    window.open(href);
  };

  return (
    <Container
      titleClassName={styles.titleContainer}
      title={{ name: "Кошелек", href: "/" }}
    >
      {isAuthenticated && (
        <div className={styles.burgerBalanceConatiner}>
          <div className={styles.burgerBalance}>
            <p>Мой баланс:</p>
            <p>
              {coins ?? 0}
              <CoinSvg />
            </p>
          </div>
        </div>
      )}
      <Tabs>
        <TabList className={styles.tabs}>
          <Tab selectedClassName={styles.active}>
            <Typography weight={600}>Пополнение</Typography>
          </Tab>
          <Tab selectedClassName={styles.active}>
            <Typography weight={600}>Вывод</Typography>
          </Tab>
        </TabList>

        <TabPanel className={styles.tabPanel}>
          <div className={styles.coinsContainer}>
            {tariffs && (
              <>
                {tariffs.map((coin) => (
                  <CoinsButton
                    key={coin.id}
                    coinImage={coin.image}
                    price={coin.cost}
                    amount={coin.coin_amount}
                    id={coin.id}
                    onClick={() => setSelectedCoin(coin.id)}
                    isSelected={coin.id === selectedCoin}
                  />
                ))}
              </>
            )}
          </div>
          <div>
            <label className={styles.checkbox}>
              <Typography color="grey" weight={500}>
                Согласен с
                <Typography as={"span"} weight={500}>
                  {" "}
                  условиями{" "}
                </Typography>
                покупки
              </Typography>
              <input
                type="checkbox"
                id="agreement"
                onChange={(event) => setIsAgree(event.target.checked)}
              />
              <span className={styles.checkmark}></span>
            </label>
          </div>
          <button
            className={styles.buyButton}
            disabled={!(selectedCoin && isAgree)}
            onClick={handleReplenishment}
          >
            <Typography size={17} weight={700}>
              Купить
            </Typography>
          </button>
          <Typography className={styles.info} size={14} weight={500}>
            Данные банковской карты защищены и вводятся на защищенной странице
            банка-эквайрера, оплата товара осуществляется безопасным способом
          </Typography>
        </TabPanel>
        <TabPanel className={styles.tabPanel}>
          <Typography size={20} weight={700}>
            Укажите сумму вывода
          </Typography>
          <Typography
            size={15}
            weight={400}
            color="grey"
            style={{ margin: "10px 0 15px" }}
          >
            Минимальная сумма 5 000
          </Typography>
          <div className={styles.inputContainer}>
            <input
              type="text"
              placeholder="5000"
              onChange={(event) => setAmount(event.target.value)}
            />
            <CoinSvg />
          </div>
          <Typography className={styles.course}>
            1 <CoinSvg /> = 0.70 <OrangeSvg />
          </Typography>
          <div className={styles.inputContainer}>
            <input type="text" placeholder="7500" />
            <PSvg />
          </div>

          <button className={styles.buyButton} onClick={handleWithdrew}>
            <Typography size={17} weight={700}>
              Вывести на карту
            </Typography>
          </button>
        </TabPanel>
      </Tabs>
    </Container>
  );
};

export default WalletPage;
