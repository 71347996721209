import clsx from "clsx";
import styles from "src/components/Buttons/Button.module.scss";
import Typography from "src/components/Typography/Typography";

export const RoundedButton = (props) => {
  const { children, className, long, ...rest } = props;

  return (
    <button
      className={clsx(styles.button, className, long && styles.long)}
      {...rest}
    >
      <Typography siz={18} align="center">
        {children}
      </Typography>
    </button>
  );
};
