import axios from "axios";
import {
  applyExtractorResponseInterceptor,
  applyHeadersRequestInterceptor,
} from "src/api/interceptors/extract-response";

export const httpClient = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 30 * 1000,
  withCredentials: true,
});

applyExtractorResponseInterceptor(httpClient);
applyHeadersRequestInterceptor(httpClient);
