import styles from "src/components/Modal/Modal.module.scss";

import logoSvg from "src/assets/images/logo.svg";

import { ReactComponent as CloseModal } from "src/assets/images/icons/close-modal.svg";

export const Modal = (props) => {
  const { children, onClose, isOpen } = props;

  return (
    <>
      {isOpen ? (
        <div className={styles.container}>
          <div className={styles.modal}>
            <CloseModal className={styles.closeButton} onClick={onClose} />
            <div className={styles.bodyWrapper}>
              <img src={logoSvg} alt="logo" className={styles.logo} />
              {children}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
