import { useEffect, useState } from "react";

export const useScreenSize = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener("resize", (event) => {
      setWidth(event.target.innerWidth);
    });
  });

  return width;
};
