import { httpClient } from "src/api/http-client";

export const TarrifApi = {
  getTarrifs: async () => {
    const response = await httpClient.get("/payments/get_plan/");

    return response;
  },

  replenishment: async (id) => {
    const response = await httpClient.get(
      `/payments/ecommpay/payment_page_url/?plan_id=${id}`
    );

    return response;
  },

  withdraw: async (amount) => {
    const response = await httpClient.post(`/payments/ecommpay/withdraw/`, {
      amount,
    });

    return response;
  },
};
