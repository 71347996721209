import { Container } from "src/components/Container/Container";

import styles from "src/pages/AboutPage/AboutPage.module.scss";

const AboutPage = () => {
  return (
    <Container title={{ name: "О нас", href: "/" }}>
      <ul className={styles.text}>
        <li>Rupor - больше, чем просто live эфиры.</li>
        <li>
          Абсолютно каждому из нас дан свой внутренний дар, развитие которого
          является нашей жизненной задачей. На пути к изучению своих талантов
          нам очень важно найти единомышленников, обогащающих нас знаниями и
          навыками.Объединять творцов и ценителей знаний, готовых покорять
          вершины посредством создания и изучения ярких live эфиров.
        </li>
        <li>
          Это платформа, позволяющая Вам зарабатывать на проведении
          live-трансляций. Темы ваших лайвов могут быть совершенно разными:
          творчество, здоровье, фитнес, точные науки, бизнес - мы соединяем
          воедино лучшее, делая вашу жизнь - увлекательнее.
        </li>
        <li>
          Так же, мы помогаем зарабатывать всем, при этом даже не выходя из
          собственного дома.
        </li>
        <li>
          Платформа Rupor позволяет монетизировать свой контент. С помощью
          эфиров внутри платформы, вы точно знаете, что ваше время и силы
          окупятся не только просмотрами большой аудитории – но и оплатой за
          проведённую трансляцию. Абсолютно любой участник трансляции сегодня
          может на прямую пообщаться со своим кумиром. Платформа Tаlk me
          позволяет выходить в прямые эфиры с любимыми блогерами и не только.
          Сделать это очень просто: достаточно купить микрофон и камеру, начав
          своё общение с ведущим. Так, вы можете реализовать свою мечту, не
          покидая границ собственного дома.
        </li>
        <li>
          Прямо внутри платформы вы можете смотреть любимые эфиры и лекции,
          прокачивая собственные скилы знаний. Тебе всего лишь надо выбрать
          понравившуюся тему и спикера. Обрати внимание на дату проведения эфира
          и не проспи начало долгожданной трансляции, став ее активным
          участником.
        </li>
        <li>
          Найти ответ на тот самый вопрос? Easy вместе с Rupor! Изучая новое, вы
          можете делиться полученными навыками со своей аудиторией. Достаточно
          запланировать и запустить прямой эфир на платформе Rupor. А
          единомышленники и поклонники найдут вас сами! Восхищены? Мы тоже!
        </li>
        <li>
          Прокачать скилы любимого хобби? Стать ближе к любимым занятиям и
          актуальному онлайн-образованию? Монетизировать свой творческий и
          уникальный взгляд на мир? Легко! Вместе с Rupor!
        </li>
        <li>Ответы на часто задаваемые вопросы:</li>
        <li>
          1. Как смотреть бесплатные трансляции?Просто выберете на сайте любой
          интересующий Вас эфир и кликните на него.Если эфир еще не начался,
          откроется страница с описанием и информацией о трансляции, и Вы
          увидите кнопку «Записаться», нажмите на нее. Поздравляем! Теперь вы
          записаны на эфир. В личном кабинете в разделе «Мои занятия» («Мои
          подписки») вы можете посмотреть все эфиры, на которые Вы записаны.
          Если бесплатный эфир уже идет в режиме Live, тогда просто нажмите на
          выбранную трансляцию и наслаждайтесь просмотром.2. Как смотреть
          трансляции за монеты? Если на балансе у Вас есть достаточное
          количество монет и эфир еще не начался, просто зайдите на страницу
          трансляции и кликните на кнопку «Войти». После этого на экране
          появится окно с подтверждением списания с баланса необходимого
          количества монет, подтвердите списание и теперь Вы записаны на
          выбранный эфир. Если эфир уже идет в режиме Live, зайти на него Вы не
          сможете. Рекомендуем заранее записываться на трансляции. Если на
          балансе нет необходимого количества монет и эфир еще не начался,
          зайдите на страницу трансляции, нажмите на кнопку «Войти» и затем
          откроется окно с пополнением баланса монет. Пополните баланс на
          необходимое количество монет и затем вернитесь на страницу эфира.
          Теперь вы можете записаться на эфир! Если эфир уже идет в режиме Live,
          зайти на него Вы не сможете. Рекомендуем заранее записываться на
          трансляции.3. Как пополнить баланс Tolki Coins (монеты)? Пополнение
          баланса в личном кабинете происходит во вкладке «Мой кошелек».
          Выберете количество монет, на которое хотите пополнить баланс и затем
          нажмите на кнопку «Пополнить». Далее вы перейдете на страницу оплаты,
          выберете удобный способ оплаты и введите необходимые данные. К оплате
          принимаются 16 и 18-значные банковские карты платежных систем Visa,
          MasterCard, МИР.4. Как вернуть монеты на баланс, когда записался
          (-ась) на трансляцию?Вы можете отменить запись на платную трансляцию.
          Отменить запись можно не позднее, чем за 8 часов до начала трансляции.
          Монеты автоматически вернутся на Ваш баланс в течение 3х дней.5. Как
          вернуть монеты на баланс, если ведущий отменил или не провел
          трансляцию?Если ведущий отменил или не провел трансляцию, монеты
          автоматически возвращаются на баланс в течение 10 дней. 6. Я купил(-а)
          монеты, хочу вернуть деньги. Как это сделать?В соответствии с офертой
          использования сайта возврат денежных средств за приобретение Монет не
          производится. Для получения денежных средств Пользователи сайта могут
          воспользоваться услугой обмена монет в соответствии с Тарифами.
        </li>
      </ul>
    </Container>
  );
};

export default AboutPage;
