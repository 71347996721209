import { httpClient } from "src/api/http-client";

export const ProfileApi = {
  getUserInfo: async () => {
    const { data } = await httpClient.get("/users/profile/");
    return data;
  },
  getUserPublicInfo: async () => {
    const { data } = await httpClient.get("/users/public_profile/");
    return data;
  },
};
